<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >

    <!-- OPTIONS & MANAGEMENT WIDGETS -->
    <v-row v-if="userLevelAboveAdmin">
      <!-- QUERY DATE-RANGE -->
      <v-col v-if="false" cols="12" lg="3">
        <v-row>
          <!-- <v-col>
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col> -->
          <!-- STATE SELECTOR -->
          <v-col>
            <v-autocomplete outlined :label="$t('comps.scopeSelector.status')"
              prepend-icon="mdi-state-machine" clearable hide-details
              v-model="scope.status"
              :items="scopedStatus" item-text="name" return-object>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-col>

      <!-- SEARCH FORM -->
      <v-col cols="12" md="6" lg="4">
        <v-card class="my-0">
          <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-radio-group row v-model="form.search.searchBy">
                      <template v-slot:label>
                        <div>{{ $t('views.merchants.searchBy') }}<br></div>
                      </template>
                      <v-radio :label="$t('views.cardpay.merchants.storeId')" value="companyId"></v-radio>
                      <v-radio :label="$t('views.cardpay.merchants.merchantId')" value="merchantId"></v-radio>
                      <!-- <v-radio :label="$t('views.cardpay.merchants.businessName')" value="businessName"></v-radio> -->
                      <v-radio :label="$t('views.cardpay.merchants.registName')" value="name"></v-radio>
                      <!-- <v-radio :label="$t('views.cardpay.merchants.ubn')" value="taxId"></v-radio> -->
                      <v-radio :label="$t('views.cardpay.merchants.businessPhone')" value="phone"></v-radio>
                      <!-- <v-radio :label="$t('views.cardpay.merchants.paymentGateway') + '(cardpay | tappay)'" value="paymentGateway"></v-radio> -->

                      <!-- <v-radio :label="$t('views.cardpay.merchants.acquirerId')" value="acquirerId"></v-radio>
                      <v-radio :label="$t('views.cardpay.merchants.acquirerSubMerchantId')" value="acquirerSubMerchantId"></v-radio> -->
                      <!-- <v-radio :label="Organization" value="organization_name"></v-radio> -->
                    </v-radio-group>
                  </v-col>
                  <v-col cols="12" class="pa-0">
                    <v-form @submit.native.prevent @submit="search">
                      <v-text-field dense outlined v-model="form.search.keyword"
                        placeholder="(Press ENTER to search)" clearable>
                      </v-text-field>
                    </v-form>
                  </v-col>
                  
                </v-row>
                
              </v-container>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Scope Selector (if it's the superadmin) -->
      <!-- <v-col cols="12" md="4">
        <ScopeSelector org-only
          @org-selected="onOrganizationSelected">
        </ScopeSelector>
      </v-col> -->

      <!-- BUTTONS FOR CREATION -->
      <!-- <v-col cols="12" md="4" class="d-flex justify-end">
        <div>
          <v-btn x-large block rounded @click="dialog.create.show = true" color="primary">
            <v-icon left>mdi-plus-box-outline</v-icon>
            {{ $t('views.merchants.createButton') }}
          </v-btn>
          <br>
          <v-btn x-large block rounded @click="showBatchCreationDialog" color="secondary">
            <v-icon left>mdi-plus-box-multiple-outline</v-icon>
            {{ $t('views.merchants.batchCreateButton') }}
          </v-btn>
        </div>
      </v-col> -->

    </v-row>
      
    <!-- LIST OF MERCHANTS -->
    <v-row>
      <v-col cols="12">

        <v-card>
          <v-card-text>

            <SSRTable ref="mch-list" :data-url="loadUrl" :data-key="'stores'" :schema="schema" @load-error="onLoadError">
              <div slot="created_at" slot-scope="{item}" class="py-1">
                <span>{{ moment.unix(item['UNIX_TIMESTAMP(created_at)']).format('YYYY/MM/DD HH:mm:ss') }}</span>
              </div>

              <div slot="merchantId" slot-scope="{item}" class="py-1">
                <span>{{ item.settings.merchantId }}</span>
              </div>

              <div slot="businessName" slot-scope="{item}" class="py-1">
                <span>{{ item.settings.businessName }}</span>
              </div>

              <div slot="taxId" slot-scope="{item}" class="py-1">
                <span>{{ item.settings.taxId }}</span>
              </div>
              <div slot="contact" slot-scope="{item}" class="py-1">
                <span class="text-body-1">{{ item.contactName }}</span><br>
                <span>{{ item.contactPhone }}</span><br>
                <span>{{ item.contactAddress }}</span>
              </div>

              <div slot="devices" slot-scope="{item}" class="py-1">
                <!-- <span v-for="(value, key) in item.devices">{{ value }} ({{ key }})</br></span> -->
                <span v-for="(d, index) in item.settings.devices" :key="index">{{ d.deviceId }} ({{ d.deviceName }})</br></span>
              </div>
              <div slot="status" slot-scope="{item}" class="py-1">
                {{ statusMap[item.status] || item.status }}
              </div>
              <div slot="actions" slot-scope="{item}">
                <!-- <v-btn fab x-small color="primary" @click="showDialogOfMerchantSettings(item)">
                  <v-icon>mdi-information-variant</v-icon>
                </v-btn> -->
                <v-btn rounded depressed small color="blue" class="ml-1"
                  @click="showDialogOfMerchantSettings(item)">
                  <v-icon left>mdi-cog</v-icon>
                  {{ $t('views.cardpay.merchants.merchantSettingsButton') }}
                </v-btn>
                <v-btn rounded depressed small color="error" class="ml-1"
                  @click="deleteCompany(item)" :disabled="dialog.delete.isLoading">
                  <v-icon left>mdi-store-remove</v-icon>
                  {{ $t('views.cardpay.merchants.deleteButton') }}
                </v-btn>
                <!-- <v-btn fab x-small elevation="0" color="warning" @click="showUpdateMerchantDialog(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn rounded depressed small color="indigo" class="ml-1"
                  @click="showDialogOfPaymentConfigs(item)">
                  <v-icon left>mdi-credit-card-outline</v-icon>
                  {{ $t('views.merchants.paymentServicesButton') }}
                </v-btn>
                <v-btn rounded depressed small color="teal darken-3" class="ml-1"
                  @click="showDialogOfCounterSettings(item)">
                  <v-icon left>mdi-cash-register</v-icon>
                  條碼管理
                </v-btn>
                <v-btn rounded depressed small color="blue" class="ml-1"
                  @click="showDialogOfMerchantSettings(item)">
                  <v-icon left>mdi-cog</v-icon>
                  {{ $t('views.merchants.merchantSettingsButton') }}
                </v-btn> -->
              </div>
            </SSRTable>

          </v-card-text>
        </v-card>

      </v-col>

    </v-row>

    <!-- Dialog - Merchant settings -->
    <v-dialog v-model="dialog.settings.show">
      <v-card>
        <CardPayMerchantSettingsView
          :company-id="dialog.settings.merchant['companyId']"
          :store-name="dialog.settings.merchant['name']"
          :store="dialog.settings.merchant">
        </CardPayMerchantSettingsView>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import DateRangePicker from '@/components/DateRangePicker';
import ScopeSelector from '@/components/ScopeSelector';

import CardPayMerchantSettingsView from '@/views/dashboard/subviews/CardPayMerchantSettingsView';

import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';
import moment from 'moment';

const Validator = SimpleVueValidation.Validator;

export default {
  components: {
    SSRTable,
    DateRangePicker,
    ScopeSelector,
    CardPayMerchantSettingsView
  },

  mounted() {

  },

  methods: {
    search() {
      console.log('[DEBUG] search', this.form.search);
      if (this.form.search.keyword && this.form.search.searchBy === 'merchantId') {
        this.form.search.searchQueryString = `&merchantId=${encodeURIComponent(this.form.search.keyword)}`;
      } else if (this.form.search.keyword) {
        this.form.search.searchQueryString = `&search=${this.form.search.searchBy}:${encodeURIComponent(this.form.search.keyword)}`;
      } else {
        this.form.search.searchQueryString = ''
      }
    },
    onDateRangeUpdated(dates) {
      this.dates = dates;
    },

    showDialogOfMerchantSettings(merchant) {
      this.dialog.settings.merchant = merchant;
      // this.dialog.settings.devices = merchant.settings?.devices;
      this.dialog.settings.show = true;
    },
    deleteCompany(item) {
      const name = item.name;
      const companyId = item.companyId;
      if (!confirm(`Sure to delete store ${name} (CMID: ${companyId})? This cannot be undone!`))
        return;
      
      this.dialog.delete.isLoading = true;
      axios.delete(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/cardpay/stores/${companyId}`
      )
      .then(response => {
        this.$refs['mch-list'].loadData();
        this.$notify({
          text: `Store deleted: ${name}(CMID: ${companyId})`,
          type: 'warn'
        });
      })
      .catch(err => {
        console.error(`Failed to delete a store ${name}(CMID: ${companyId})`);
        this.$notify({
          text: `Merchant deletion failed ${name}(CMID: ${companyId}): ${err.message}`,
          type: 'error'
        });
      })
      .finally(() => {
        this.dialog.delete.isLoading = false;
      });
    },
    onLoadError(err){
      this.$notify({
          text: err.message,
          type: 'error'
        });
    }

  },

  data() {
    return {
      moment,
      dates: [
        moment().startOf('day').subtract(1, 'months').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      // scopedStatus: [
      //   {
      //     value: 'active',
      //     name:  '已開通'
      //   }, 
      //   {
      //     value: 'deleted',
      //     name:  '銷戶'
      //   },
      //   {
      //     value: 'inactive',
      //     name:  '暫時停權'
      //   },
      //   {
      //     value: 'review-pending',
      //     name:  '暫時收款'
      //   }],
      scope: {
        organization: null,
        status: null
      },

      form: {
        search: {
          searchBy: 'name',
          keyword: '',
          searchQueryString: ''
        }
      },

      dialog: {
        settings: {
          show: false,
          merchant: { },
          companyId: null
          // devices: [],
        },
        info: {
          show: false,
          merchant: { }
        },
        delete: {
          isLoading: false
        },
        // create: {
        //   show: false,
        //   isLoading: false,
        //   model: {
        //     orgId: null,
        //     merchantId: '',
        //     name: '', email: '', phone: '', address: '',
        //     applyDefaultPaymentConfigs: true
        //   }
        // },
        // update: {
        //   show: false,
        //   isLoading: false,
        //   merchant: { },
        //   model: {
        //     name: '', email: '', phone: '', address: ''
        //   }
        // },

        batchCreate: {
          show: false
        },

      }
    };
  },

  computed: {
    loadUrl() {
      // let from = moment(this.dates[0]).startOf('day');
      // let to = moment(this.dates[1]).endOf('day');
      let from = null;
      let to = null;
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/cardpay/stores?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
        // + `&from=${from.unix()}&to=${to.unix()}`
        + (from ? `&from=${from.unix()}` : '')
        + (to ? `&to=${to.unix()}`  : '')
        + (this.scope.status? `&status=${this.scope.status['value']}`: '')
        + this.form.search.searchQueryString;
    },
    
    schema() {
      let ret = [
        { value: 'created_at', text: this.$t('views.cardpay.merchants.createdAt') },
        { value: 'companyId', text: this.$t('views.cardpay.merchants.storeId'), sortable: false },
        { value: 'merchantId', text: this.$t('views.cardpay.merchants.merchantId'), sortable: false },
        { value: 'businessName', text: this.$t('views.cardpay.merchants.businessName'), sortable: false },
        { value: 'name', text: this.$t('views.cardpay.merchants.registName'), sortable: false },
        { value: 'taxId', text: this.$t('views.cardpay.merchants.ubn'), sortable: false },
        { value: 'contact', text: this.$t('views.cardpay.merchants.contact'), sortable: false },
        { value: 'devices', text: this.$t('views.cardpay.merchants.acquirerSubMerchantId'), sortable: false },
        { value: 'status', text: this.$t('views.cardpay.merchants.status'), sortable: false },
        
      ];
      // if (this.user['level'] === 'superadmin')
      //   ret.push({ value: 'organization_name', text: this.$t('views.merchants.organization') });
      ret.push({ value: 'actions', text: this.$t('views.cardpay.merchants.actions'), sortable: false });
      return ret;
    },

    user() {
      return this.$store.getters.user;
    },
    userLevelAboveAdmin() {
      return [ 'superadmin', 'admin' ].indexOf(this.$store.getters.user['level']) >= 0;
    },

    statusMap(){
      return {
        "active": this.$t('views.cardpay.merchants.storeStatus.active'),
        "deleted": this.$t('views.cardpay.merchants.storeStatus.deleted'),
        "inactive": this.$t('views.cardpay.merchants.storeStatus.inactive'),
        "review-pending":this.$t('views.cardpay.merchants.storeStatus.review-pending'),
        // "suspend": "暫時收款"
      }

    }
  },

  validators: {
    'dialog.create.model.merchantId': v => {
      return Validator.value(v).required().minLength(8);
    },
    'dialog.create.model.name': v => {
      return Validator.value(v).required().minLength(2);
    },
    'dialog.create.model.email': v => {
      return Validator.value(v).required().email();
    },
    'dialog.create.model.phone': v => {
      return Validator.value(v).required().regex(new RegExp('^0[0-9]{8,}$', 'gm'));
    },
    'dialog.create.model.address': v => {
      return Validator.value(v).required();
    },
    'dialog.update.model.name': v => {
      return Validator.value(v).required().minLength(2);
    },
    'dialog.update.model.email': v => {
      return Validator.value(v).required().email();
    },
    'dialog.update.model.phone': v => {
      return Validator.value(v).required().regex(new RegExp('^0[0-9]{8,}$', 'gm'));
    },
    'dialog.update.model.address': v => {
      return Validator.value(v).required();
    },
  }
}
</script>
