var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h2',[_vm._v(_vm._s(_vm.$t('views.cardpay.viewMerchantInfo.title'))+" | "+_vm._s(_vm.companyId))]),_c('p',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.storeName))]),_c('span',{staticClass:"text-h3"},[_vm._v("子特店資訊")]),_c('v-divider',{staticClass:"mb-2"}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"px-1",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.subMerchantsSchema,"items":_vm.devices}})],1)],1)],1),_c('br'),_c('span',{staticClass:"text-h3"},[_vm._v("綁定商家")]),_c('v-divider',{staticClass:"mb-2"}),_c('v-row',[_c('v-col',{attrs:{"col":"12","md":"6"}},[_c('v-card',{staticClass:"px-1",attrs:{"outlined":""}},[_c('v-form',{on:{"submit":_vm.search},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","placeholder":"(Press ENTER to search)","clearable":""},model:{value:(_vm.form.shop.search.keyword),callback:function ($$v) {_vm.$set(_vm.form.shop.search, "keyword", $$v)},expression:"form.shop.search.keyword"}})],1),_c('SSRTable',{ref:"list-shops",attrs:{"data-url":_vm.loadShopsUrl,"data-key":'merchants',"schema":_vm.shopsSchema},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.name))]),_c('br'),_c('span',{staticStyle:{"color":"#999999"}},[_vm._v(_vm._s(item.merchant_id))])])}},{key:"contact",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[_c('span',{staticClass:"text-body-1"},[_vm._v(_vm._s(item.phone))]),_c('br'),_c('span',[_vm._v(_vm._s(item.email))]),_c('br'),_c('span',[_vm._v(_vm._s(item.address))])])}},{key:"invoice",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[(item.invoice && item.invoice.type === 'b2b')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('views.merchants.invoice.B2B'))+" ")]):(item.invoice && item.invoice.type === 'b2c')?_c('span',[_vm._v(" "+_vm._s(_vm.$t('views.merchants.invoice.B2C'))+" ")]):_vm._e(),_c('br'),_c('span',[_vm._v(_vm._s(item.invoice ? item.invoice.title : ''))]),_c('br'),_c('span',[_vm._v(_vm._s(item.invoice ? item.invoice.taxId : ''))]),_c('br'),_c('span',[_vm._v(_vm._s(item.invoice ? item.invoice.email : ''))])])}},{key:"beneficiary",fn:function(ref){
var item = ref.item;
return _c('div',{staticClass:"py-1"},[_c('span',[_vm._v(_vm._s(item.beneficiary ? item.beneficiary.bankName : ''))]),_c('br'),_c('span',[_vm._v(_vm._s(item.beneficiary ? item.beneficiary.bankId : ''))]),_c('br'),_c('span',[_vm._v(_vm._s(item.beneficiary ? item.beneficiary.accountName : ''))]),_c('br'),_c('span',[_vm._v(_vm._s(item.beneficiary ? item.beneficiary.accountNo : ''))])])}},{key:"actions",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('v-btn',{staticClass:"ml-1",attrs:{"rounded":"","depressed":"","small":"","color":"blue"},on:{"click":function($event){return _vm.deleteShop(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-store-remove")]),_vm._v(" "+_vm._s(_vm.$t('views.cardpay.viewMerchantInfo.form.buttonDelete'))+" ")],1)],1)}}])})],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-form',{attrs:{"disabled":_vm.form.shop.isLoading}},[_c('v-container',[_c('v-row',[(_vm.form.shop.mode == 'create')?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","type":"text","label":_vm.$t('views.cardpay.viewMerchantInfo.table.shopId'),"error-messages":_vm.validation.firstError('form.shop.shopId')},model:{value:(_vm.form.shop.shopId),callback:function ($$v) {_vm.$set(_vm.form.shop, "shopId", $$v)},expression:"form.shop.shopId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.form.shop.mode == 'create')?[_c('v-btn',{attrs:{"rounded":"","color":"warning","disabled":_vm.form.shop.isLoading},on:{"click":_vm.addShop}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-store-plus")]),_vm._v(" "+_vm._s(_vm.$t('views.cardpay.viewMerchantInfo.form.buttonAdd'))+" ")],1)]:_vm._e()],2)],1)],1)],1)],1)],1),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }