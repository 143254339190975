<template>
  <!-- WARNING: Only users above admin can use this component! -->
  <v-row v-if="['superadmin', 'admin'].indexOf(userLevel) >= 0">

    <!-- Organization Selector -->
    <v-col :cols="vertical? 12: 6" v-if="userLevel == 'superadmin'">
      <v-autocomplete :dense="dense" outlined :label="$t('comps.scopeSelector.organizations')"
        prepend-icon="mdi-domain" clearable hide-details
        v-model="selectedOrganization"
        :items="organizations" item-text="name" return-object
        :filter="filterOrganizations" @change="onOrganizationSelected">
      </v-autocomplete>      
    </v-col>

    <!-- Merchant Selector -->
    <v-col :cols="vertical? 12: 6" v-if="!orgOnly">
      <v-autocomplete :dense="dense" outlined :label="$t('comps.scopeSelector.merchants')"
        prepend-icon="mdi-store-outline" clearable hide-details
        v-model="selectedMerchant"
        :items="scopedMerchants" item-text="name" return-object
        :filter="filterMerchants" @change="onMerchantSelected">
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ScopeSelector',
  props: {
    dense: Boolean,
    vertical: Boolean,
    orgOnly: Boolean,

    preselectedMerchantId: String
  },

  mounted() {
    if (this.userLevel === 'superadmin')
      this.fetchOrganizations();
    if (!this.orgOnly)
      this.fetchMerchants();
  },

  methods: {
    fetchOrganizations() {
      if (!this.$store.getters.organizations.length)
        this.$store.dispatch('fetchOrganizations');
      // axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/orgs`)
      // .then(response => {
      //   this.organizations = response.data;
      // })
      // .catch(err => {
      //   console.error(`[ScopeSelector] fetchOrganization error`, err);
      // });
    },

    fetchMerchants() {
      axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants?limit=-1&include-beneficiaries=false`)
      .then(response => {
        this.merchants = response.data['merchants'];
        if (this.preselectedMerchantId)
          this.selectedMerchant = this.merchants.find(m => this.preselectedMerchantId === m['merchant_id']);
      })
      .catch(err => {
        console.error(`[ScopeSelector] fetchMerchants error`, err);
      });
    },

    filterOrganizations(item, queryText) {
      let keyword = queryText.toLowerCase();
      let orgName = item['name'].toLowerCase();
      return orgName.indexOf(keyword) >= 0 || item['alias'].indexOf(keyword);
    },
    filterMerchants(item, queryText) {
      let keyword = queryText.toLowerCase();
      let mchName = item['name'].toLowerCase();
      let mchId = item['merchant_id'].toLowerCase();
      return mchName.includes(keyword) || mchId.includes(keyword);
    },

    onOrganizationSelected() {
      this.$emit('org-selected', this.selectedOrganization);
    },
    onMerchantSelected() {
      this.$emit('mch-selected', this.selectedMerchant);
    }
  },

  data() {
    return {
      // organizations: [ ],
      merchants: [ ],

      selectedOrganization: null,
      selectedMerchant: null
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    userLevel() {
      return this.$store.getters.user.level;
    },
    organizations() {
      return this.$store.getters.organizations;
    },

    scopedMerchants() {
      // console.log(`[ScopeSelector][DEBUG] computed scopedMerchants`, this.selectedOrganization);
      // console.log(`[ScopeSelector][DEBUG] merchants`, this.merchants);
      if (this.selectedOrganization) {
        return this.merchants.filter(m => {
          return m['organization_alias'] === this.selectedOrganization['alias'];
        });
      }
      return this.merchants;
    }
  }
}
</script>