<template>
  <v-container>

    <h2>{{ $t('views.cardpay.viewMerchantInfo.title') }} | {{ companyId }}</h2>
    <p class="text-h4">{{ storeName }}</p>
    <!-- SECTION - 銀行帳戶資訊 -->
    <!-- <span class="text-h3">銀行帳戶資訊</span>
    <v-divider class="mb-2"></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-card outlined class="px-1">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.cardpay.viewMerchantInfo.beneficiary.bankName') }}</td>
                  <td>{{ beneficiary.bankName }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.cardpay.viewMerchantInfo.beneficiary.bankBranch') }}</td>
                  <td>{{ beneficiary.bankBranch }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.cardpay.viewMerchantInfo.beneficiary.bankCode') }}</td>
                  <td>{{ beneficiary.bankCode }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.cardpay.viewMerchantInfo.beneficiary.accountName') }}</td>
                  <td>{{ beneficiary.accountName }}</td>
                </tr>
                <tr>
                  <td class="font-weight-medium">{{ $t('views.cardpay.viewMerchantInfo.beneficiary.accountNo') }}</td>
                  <td>{{ beneficiary.accountNo }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card>
      </v-col>
    </v-row>
    <br/> -->
    <!-- 
    <v-data-table hide-default-footer
      hide-default-header
      :headers="beneficiarySchema"
      :items="beneficiary">
      <template v-slot:item.label="{ item }">
        <strong><b>{{ item.label }}</b></strong>
      </template>
    </v-data-table> 
    -->
    <!-- SECTION - SUB-MERCHANT INFO -->
    <span class="text-h3">子特店資訊</span>
    <v-divider class="mb-2"></v-divider>
    <v-row>
      <v-col cols="12" md="6">
        <v-card outlined class="px-1">
          <v-data-table hide-default-footer
            :headers="subMerchantsSchema"
            :items="devices">
          </v-data-table>
          <!-- <SSRTable ref="list-subMerchants"
            :data-url="loadSubMerchantsUrl" :data-key="'employees'" :schema="subMerchantsSchema"
          >
          </SSRTable> -->
        </v-card>
      </v-col>
    </v-row>
    <br/>
    <!-- SECTION - 綁定商家 INFO -->
    <span class="text-h3">綁定商家</span>
    <v-divider class="mb-2"></v-divider>
    <v-row>
      <v-col col="12" md="6">
        <v-card outlined class="px-1">
          <v-form @submit.native.prevent @submit="search">
            <v-text-field dense outlined v-model="form.shop.search.keyword"
              placeholder="(Press ENTER to search)" clearable>
            </v-text-field>
          </v-form>  
                    
          <SSRTable ref="list-shops"
            :data-url="loadShopsUrl" :data-key="'merchants'" :schema="shopsSchema"
          >
            <div slot="name" slot-scope="{item}">
              <span class="text-body-1">{{ item.name }}</span><br>
              <span style="color:#999999">{{ item.merchant_id }}</span>
            </div>
            <div slot="contact" slot-scope="{item}" class="py-1">
              <span class="text-body-1">{{ item.phone }}</span><br>
              <span>{{ item.email }}</span><br>
              <span>{{ item.address }}</span>
            </div>
            <div slot="invoice" slot-scope="{item}" class="py-1">
              <span v-if="item.invoice && item.invoice.type === 'b2b'">
                {{ $t('views.merchants.invoice.B2B') }}
              </span>
              <span v-else-if="item.invoice && item.invoice.type === 'b2c'">
                {{ $t('views.merchants.invoice.B2C') }}
              </span>
              <br/>
              <span>{{ item.invoice ? item.invoice.title : '' }}</span><br/>
              <span>{{ item.invoice ? item.invoice.taxId : '' }}</span><br/>
              <span>{{ item.invoice ? item.invoice.email : '' }}</span>
            </div>
            <div slot="beneficiary" slot-scope="{item}" class="py-1">
              <span>{{ item.beneficiary ? item.beneficiary.bankName  : '' }}</span><br/>
              <span>{{ item.beneficiary ? item.beneficiary.bankId : '' }}</span><br/>
              <span>{{ item.beneficiary ? item.beneficiary.accountName : '' }}</span><br/>
              <span>{{ item.beneficiary ? item.beneficiary.accountNo : '' }}</span>
            </div>
            <div slot="actions" slot-scope="{item}">
              <v-btn rounded depressed small color="blue" class="ml-1"
                  @click="deleteShop(item)">
                  <v-icon left>mdi-store-remove</v-icon>
                  {{ $t('views.cardpay.viewMerchantInfo.form.buttonDelete') }}
                </v-btn>
            </div>
          </SSRTable>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-form :disabled="form.shop.isLoading">
          <v-container>
            <v-row>
              
              <v-col cols="12" md="6" v-if="form.shop.mode == 'create'">
                <v-text-field dense filled type="text" :label="$t('views.cardpay.viewMerchantInfo.table.shopId')"
                  v-model="form.shop.shopId"
                  :error-messages="validation.firstError('form.shop.shopId')">
                </v-text-field>
                <!-- 多店綁定測試 -->
                <!-- <v-combobox
                  v-model="form.shop.shopIds"
                  chips
                  clearable
                  multiple
                  filled
                  append-icon=""
                >
                  <template v-slot:selection="{ attrs, item, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click:close="remove(item)"
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                </v-combobox> -->

              </v-col>
              
              <v-col cols="12" md="6">
                <template v-if="form.shop.mode == 'create'">
                  <v-btn rounded color="warning" :disabled="form.shop.isLoading"
                    @click="addShop">
                    <v-icon left>mdi-store-plus</v-icon>
                    {{ $t('views.cardpay.viewMerchantInfo.form.buttonAdd') }}
                  </v-btn>
                </template>
                <!-- <template v-else>
                  <v-btn rounded color="error" :disabled="form.shop.isLoading"
                    @click="deleteShop">
                    <v-icon left>mdi-store-remove</v-icon>
                    {{ $t('views.cardpay.viewMerchantInfo.form.buttonDelete') }}
                  </v-btn>
                </template> -->
              </v-col>
            </v-row>
          </v-container>

        </v-form>
      </v-col>
    </v-row>
    <br/>
    
  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';

import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';

const Validator = SimpleVueValidation.Validator;

export default {
  name: 'CardPayMerchantInfo',
  components: {
    SSRTable   
  },
  props: {
    companyId: String, //次特店ID
    storeName: String, //主綁定商店名稱
    store: Object,
    // devices: Array
  },

  methods: {
    addShop() {
      this.$validate()
      .then(valid => {
        if (!valid) return;
        let shopId = this.form.shop.shopId;
        // let shopIds = this.form.shop.shopId ? this.form.shop.shopId.split(",").map(item => item.trim()) || [];
        this.form.shop.isLoading = true;
        const data = {
          merchantIds: [ shopId ]
          // merchantIds: this.form.shop.shopIds
        };
        axios.post(
          `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/cardpay/stores/${this.companyId}/merchants`,
          data
        )
        .then(response => {
          this.$refs['list-shops'].loadData();
          this.$notify({
            text: `${shopId} created.`,
            type: 'success'
          });
          //NOTE: Reset employee form
          this.form.shop.shopId = '';
          this.validation.reset();
        })
        .catch(err => {
          console.error(`Failed to bind shop`, err.response);
          this.$notify({
            text: `綁定商店${shopId}失敗，請確認此店家是否已綁定過其他次特店，若有，請先解綁再重新綁定。`,
            type: 'error'
          });
        })
        .finally(() => {
          this.form.shop.isLoading = false;
          this.form.shop.shopId = '';
          this.validation.reset();
        });
      });
    },
    deleteShop(item) {
      console.log(JSON.stringify(item));
      const merchantId = item.merchant_id;
      const name =  item.name;
      if (!confirm(`Are you sure to unbind ${name}（MID：${merchantId}）?`)) return;

      this.form.shop.isLoading = true;
      axios.delete(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/cardpay/stores/${this.companyId}/merchants/${merchantId}`
      )
      .then(response => {
        this.$refs['list-shops'].loadData();
        this.$notify({
          text: `Unbind ${name}（MID：${merchantId}） successfully.`,
          type: 'success'
        });
      })
      .catch(err => {
        console.error(`Failed to unbind ${name}（MID：${merchantId}）`, err);
        this.$notify({
          text: `Failed to unbind ${name}（MID：${merchantId}）`,
          type: 'error'
        });
      })
      .finally(() => {
        this.form.shop.isLoading = false;
        //NOTE: Reset shop form
        // this.form.shop.shopId = null;
        // this.validation.reset();
      });
    },
    search() {
      console.log('[DEBUG] search', this.form.shop.search);
      if (this.form.shop.search.keyword)
        this.form.shop.search.searchQueryString = `&search=${this.form.shop.search.searchBy}:${encodeURIComponent(this.form.shop.search.keyword)}`;
      else 
        this.form.shop.search.searchQueryString = '';
    },
    // remove(item) {
    //   this.form.shop.shopIds = this.form.shop.shopIds.filter(e => e !== item);
    // }
  },

  data() {
    return {
      scope: {
        organization: null,
        shopId: null 
      },
      form: {
        shop: { // 綁定商店
          isLoading: false,
          mode: 'create',
          shopId: null,
          search: {
            searchBy: 'merchant_id',
            keyword: null,
            searchQueryString: ''
          }
          // shopIds: []
        },

      }
    };
  },

  computed: {
    // loadSubMerchantsUrl() {
    //   return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants/${this.companyId}/employees`
    //     + `?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`;
    // },
    subMerchantsSchema() {
      return [
        { value: 'deviceId', text: this.$t('views.cardpay.viewMerchantInfo.table.subAcquirerId') },
        { value: 'deviceName', text: this.$t('views.cardpay.viewMerchantInfo.table.subAcquirerName') },
        // { value: 'actions', text: this.$t('views.cardpay.viewMerchantInfo.table.actions') },
      ];
    },

    loadShopsUrl() {
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/merchants?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
        + `&companyId=${this.companyId}`
        + this.form.shop.search.searchQueryString;
    },
    shopsSchema() {
      return [
        { value: 'name', text: this.$t('views.merchants.name'), sortable: false },
        { value: 'contact', text: this.$t('views.merchants.contact'), sortable: false },
        { value: 'invoice', text: this.$t('views.merchants.invoiceInfo'), sortable: false },
        { value: 'beneficiary', text: this.$t('views.merchants.beneficiary'), sortable: false },
        { value: 'actions', text: this.$t('views.cardpay.viewMerchantInfo.table.actions'), sortable: false  }
      ];
    },

    user() {
      return this.$store.getters.user;
    },
    organizations() {
      return this.$store.getters.organizations.map(org => {
        return {
          text: org['name'],
          value: org['id']
        };
      });
    },
    devices () {
      return this.store?.settings?.devices || [];
    },
    // beneficiarySchema() {
    //   return [
    //     { value: 'label', text: ''},
    //     { value: 'value', text: '' },
    //   ];
    // },
    // beneficiary () {
    //   return this.store?.settings?.beneficiary || {}
    // }

  },

  validators: {
    'form.shop.shopId': v => {
      return Validator.value(v).required().minLength(8);
    }
  }
}
</script>

<style scoped>
img.disabled-filter {
  filter: grayscale(100%);
}
</style>